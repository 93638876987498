.item-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    height: 50px;
    background-color: #fff;
    margin-top: 50px;
    border-radius: 10px;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .team {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    gap: 10px;
    height: 100%;
    width: 50%;
  }
 

  
  .item-title {
    font-size: 20px;
    font-weight: bold;
  }
  
  .item-logo {
    width: 50px;
    height: 50px;
  }

  .item-hyphen {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    width: 10%;
  }

  @media (max-width: 780px) {
    .item-list {
      width: 70%;
      margin-top: 20px;
      padding: 10px;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 0;


    }
    .item-logo {
      width: 30px;
      height: 30px;
    }

    .item-title {
      font-size: 14px;
    }

  }