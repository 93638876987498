.timebar-container {
  display: flex;
  width: 50%;
  background-color: #282c34;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  height: 70px;
  border-radius: 10px;
}

@media (max-width: 780px) {
  .timebar-container {
    gap: 0.5rem;
    width: 100%;
    border-radius: 0px;
  }
}